<template>
	<div :class="['block-inner', blockStyle]">
		<component :is="iconComponent" v-if="iconComponent" class="style-icon" :width="32" :height="32" />

		<div v-html="blockContent"></div>
	</div>
</template>

<script>
	import {jsonToHtml} from '../../inc/text';
	import IconInstructions from '../icons/IconInstructions';
	import IconHelp from '../icons/IconHelp';
	import IconInfo from '../icons/IconInformation';
	import IconWarning from '../icons/IconWarning';

	export default {
		name: 'BlockText',
		components: {
			IconInstructions,
			IconHelp,
			IconInfo,
			IconWarning
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			blockContent() {
				return jsonToHtml(this.block.content);
			},

			blockStyle() {
				if(typeof this.block.meta.style === 'string') {
					return this.block.meta.style;
				}

				return 'regular';
			},

			iconComponent() {
				if(this.blockStyle === 'regular') {
					return false;
				}

				return 'Icon' + this.blockStyle[0].toUpperCase() + this.blockStyle.substr(1);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@mixin text-block($color) {
		background: rgba($color, 0.05);
		border-top: 1px solid rgba($color, 0.2) !important;
		border-bottom: 1px solid rgba($color, 0.2);

		.style-icon {
			color: $color;
		}
	}

	.block-inner:not(.regular) {
		font-weight: $font__semibold;
	}

	.block-inner::v-deep {
		h2 {
			font-size: 1.3em;
			margin-top: 1.5em;
			margin-bottom: 0.25em;
		}

		h3 {
			font-size: 1em;
			line-height: $line__height;
			margin: 0;
		}

		li {
			margin-bottom: 0.6em;
		}

		:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	.style-icon {
		position: absolute;
		top: 50%;
		left: $site__padding - 3px;
		transform: translate(-25%, -50%);
	}

	.instructions {
		@include text-block($color__orange);
	}

	.help {
		@include text-block($color__green);
	}

	.warning {
		@include text-block($color__red);
	}

	.info {
		@include text-block($color__blue);
	}

	@include breakpoint('small') {
		.style-icon {
			top: $default_padding / 2;
			left: $default_padding / 2;
			transform: none;
			width: 24px;
			height: 24px;
		}
	}
</style>