<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><line x1="16" y1="5.8" x2="16" y2="31" fill="none" stroke-miterlimit="10" stroke-linecap="butt" /> <path d="M23.5,2C19.4,2,16,3.6,16,5.8,16,3.6,12.6,2,8.5,2S1,3.6,1,5.8V31c0-2.3,3.4-4,7.5-4S16,28.7,16,31c0-2.3,3.4-4,7.5-4S31,28.7,31,31V5.8C31,3.6,27.6,2,23.5,2Z" fill="none" stroke-miterlimit="10" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>